import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
   baseURL: (process.env.NODE_ENV === 'production')
       ? 'https://ru-api.sifnf.ru/api/'
       : 'http://ru-sifnf.local/api/',
})

Vue.prototype.$httpRu = axiosIns

console.log("AXIOS RU!!")
console.log(axiosIns)

export default axiosIns
