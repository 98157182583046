export default [
  {
    path: '/db/movies-old',
    name: 'movies-old',
    component: () => import('@/views/database/MoviesList.vue'),
  },
  {
    path: '/db/movies',
    name: 'movies',
    component: () => import('@/views/database/MoviesTable.vue'),
  },
  {
    path: '/db/movie/:id/edit',
    name: 'movie-edit',
    component: () => import('@/views/database/MovieEdit.vue'),
  },
  {
    path: '/db/movie/:id',
    name: 'movie-view',
    component: () => import('@/views/database/MovieDetail.vue'),
  },
  {
    path: '/db/people/:id',
    name: 'person-view',
    component: () => import('@/views/database/PersonDetail.vue'),
  },
  {
    path: '/db/movies/premiers',
    name: 'movies-premiers',
    component: () => import('@/views/database/MoviesPremiers.vue'),
  },
  {
    path: '/db/movies/top',
    name: 'movies-top',
    component: () => import('@/views/database/MoviesTop.vue'),
  },
  {
    path: '/db/movies/filter',
    name: 'movies-filter',
    component: () => import('@/views/database/MoviesFilter.vue'),
  },
  {
    path: '/db/movies/search',
    name: 'movies-search',
    component: () => import('@/views/database/MoviesSearch.vue'),
  },
  {
    path: '/db/movies/kinopub',
    name: 'movies-kinopub',
    component: () => import('@/views/database/import/FileList.vue'),
  },
  {
    path: '/db/movies/kinopub/import/:filename',
    name: 'movies-kinopub-import',
    component: () => import('@/views/database/import/ImportFile.vue'),
  },
  {
    path: '/db/movies/collections',
    name: 'movies-collections',
    component: () => import('@/views/database/import/KpCollectionsList.vue'),
  },
  {
    path: '/db/movies/collection/import/:slug',
    name: 'movies-collection-import',
    component: () => import('@/views/database/import/ImportCollection.vue'),
  },
  {
    path: '/db/artists',
    name: 'artists',
    component: () => import('@/views/db-music/ArtistsList.vue'),
  },
  {
    path: '/db/artists/search',
    name: 'artists-search',
    component: () => import('@/views/db-music/ArtistsSearch.vue'),
  },
  {
    path: '/db/artist/:id',
    name: 'artist-view',
    component: () => import('@/views/db-music/ArtistDetail.vue'),
  },
  {
    path: '/db/track/:id/search',
    name: 'track-search',
    component: () => import('@/views/db-music/TrackSearch.vue'),
  },
  {
    path: '/db/autotopics',
    name: 'autotopics',
    component: () => import('@/views/db-music/TopicsList'),
  },
  {
    path: '/db/playlist-search',
    name: 'playlist-search',
    component: () => import('@/views/db-music/PlaylistSearch'),
  },
  {
    path: '/db/:streaming/playlist/:owner_id/:id',
    name: 'yandex-playlist-view',
    component: () => import('@/views/db-music/PlaylistView'),
  },
  {
    path: '/db/:streaming/playlist/:id',
    name: 'spotify-playlist-view',
    component: () => import('@/views/db-music/PlaylistView'),
  },
]
